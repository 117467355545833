import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  url: string;
  title: string;
}

const SchemaOrg: React.FC<Props> = React.memo(({ url, title }) => {
  const schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': `#webpage`,
      url,
      name: title,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      '@id': `#organization`,
      name: 'Cara by HiDoc Technologies',
      url,
      sameAs: [
        'https://www.linkedin.com/company/hidoc',
        'https://www.facebook.com/pg/caracare.health/',
        'https://twitter.com/cara_by_hidoc',
        'https://www.youtube.com/channel/UCtYoyEXFu_M9vOE0-YXwBHQ',
        'https://www.instagram.com/caracare_health/',
      ],
      address: {
        '@type': 'PostalAddress',
        addressCountry: 'DE',
        addressLocality: 'Berlin',
        addressRegion: 'BE',
        postalCode: '10119',
        streetAddress: 'Torstraße 59',
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.5',
        ratingCount: '38',
      },
    },
  ];

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
});

export default SchemaOrg;
