import React from 'react';
import { useLocation } from '@reach/router';
import { useIntl } from 'react-intl';
import { FiChevronDown } from 'react-icons/fi';
import locales from '../../locales/config';
import { MONO_SYSTEM_FONTS } from '../../styles/typography';
import { css } from '@emotion/core';

const selectorStyles = css`
  position: relative;
  display: table-cell;
  font-family: ${['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', ')};
  font-size: 14px;
  height: 30px;§
  border: none;
  border-radius: 5px;
`;

const containerStyles = css`
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
`;

// Extract the path after the locale from the pathname
// e.g. /en/program/program-name -> /program/program-name
export const extractPathAfterLocale = (pathname: string, locale: string) => {
  const localePrefix = `/${locale}`;
  const pathAfterLocale = pathname.slice(localePrefix.length);

  return pathAfterLocale;
};

export default function LanguageSelect({
  isDarkHeader,
  isMobileMenu,
  ...rest
}: {
  isDarkHeader: boolean;
  isMobileMenu?: boolean;
}) {
  const intl = useIntl();
  const location = useLocation();

  const handleChangeLocale = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const nextLocale = event.target.value;
    window.location.href = `${location.origin}/${nextLocale}`;
  };

  return (
    <div
      css={
        isMobileMenu
          ? containerStyles
          : {
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
      }
      {...rest}
    >
      <select
        onChange={handleChangeLocale}
        defaultValue={intl.locale}
        aria-label="Language"
        css={
          isMobileMenu
            ? selectorStyles
            : {
                paddingRight: 16,
                fontFamily: ['Source Code Pro']
                  .concat(MONO_SYSTEM_FONTS)
                  .join(', '),
                fontSize: 12,
                fontWeight: 500,
                color: isDarkHeader ? '#fffdfc' : '#160B2C',
                letterSpacing: 0.5,
                borderRadius: 0,
                border: 0,
                outline: 0,
                backgroundColor: 'transparent',
                opacity: 0.9,
                appearance: 'none',
              }
        }
      >
        {Object.values(locales).map((lang) => (
          <option key={lang.path} value={lang.path}>
            {lang.locale}
          </option>
        ))}
      </select>
      {!isMobileMenu && (
        <span
          css={{
            display: 'inline-flex',
            position: 'absolute',
            right: 0,
            pointerEvents: 'none',
          }}
        >
          <FiChevronDown
            size={16}
            color={isDarkHeader ? '#fffdfc' : '#160B2C'}
          />
        </span>
      )}
    </div>
  );
}
