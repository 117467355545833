import React, { useState } from 'react';
import NewHeader from '../Homepage/Header';
import NewMobileMenu from '../Homepage/MobileMenu';
import { graphql, useStaticQuery } from 'gatsby';
import { ContentfulCategoryConnection } from '../../graphql-types';
import { useIntl } from 'react-intl';
import { CARRERS_LINK, CONTACT_US_LINK } from '../../utils/constants';

interface LayoutHeaderProps {
  hideLanguageSwap: boolean;
  isNewLayout: boolean;
  isDarkHeader: boolean;
  hideCallToAction?: boolean;
  prescriptionLink?: string;
  simpleHeader?: boolean;
}

const LayoutHeader: React.FC<LayoutHeaderProps> = ({
  isDarkHeader,
  hideCallToAction,
  prescriptionLink,
  simpleHeader,
}) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const intl = useIntl();

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const data = useStaticQuery<{
    allContentfulCategory: ContentfulCategoryConnection;
  }>(graphql`
    query DropDownMenuQuery {
      allContentfulCategory(
        filter: {
          firstLevel: { eq: true }
          node_locale: { in: ["de", "en"] }
          isLocalized: { eq: true }
        }
        sort: { fields: menuPosition, order: ASC }
      ) {
        edges {
          node {
            node_locale
            id
            title
            slug
            firstLevel
            childCategories {
              title
              slug
              isLocalized
              childCategories {
                title
                slug
                isLocalized
              }
            }
          }
        }
      }
    }
  `);

  const aboutPages = [
    {
      id: 'about-us',
      url: '/about',
      title: intl.formatMessage({
        id: 'nav.header.about.us.page.one',
        defaultMessage: 'Cara Care',
      }),
    },
    {
      id: 'science',
      url: '/science',
      title: intl.formatMessage({
        id: 'nav.header.about.us.page.two',
        defaultMessage: 'Wissenschaftlichkeit',
      }),
    },
    {
      id: 'careers',
      url: CARRERS_LINK,
      title: intl.formatMessage({
        id: 'nav.header.about.us.page.three',
        defaultMessage: 'Karriere',
      }),
    },
    {
      id: 'press',
      url: '/press',
      title: intl.formatMessage({
        id: 'nav.header.about.us.page.four',
        defaultMessage: 'Presse',
      }),
    },
    {
      id: 'contact',
      url: CONTACT_US_LINK,
      title: intl.formatMessage({
        id: 'nav.header.about.us.page.five',
        defaultMessage: 'Kontakt',
      }),
    },
  ];

  const categories = data.allContentfulCategory.edges.filter(
    ({ node }) => node.node_locale === intl.locale
  );

  return (
    <>
      <NewHeader
        onOpenMobileMenu={openMobileMenu}
        {...{ isDarkHeader }}
        categories={categories}
        aboutPages={aboutPages}
        hideCallToAction={hideCallToAction}
        prescriptionLink={prescriptionLink}
        simpleHeader={simpleHeader}
      />
      <NewMobileMenu
        isOpen={isMobileMenuOpen}
        onClose={closeMobileMenu}
        categories={categories}
        aboutPages={aboutPages}
        hideCallToAction={hideCallToAction}
      />
    </>
  );
};

export default LayoutHeader;
