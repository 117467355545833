import React from 'react';

interface Props {
  to: string;
  blank?: boolean;
}

const ExternalLink: React.FC<Props> = ({ to, blank, children, ...rest }) => (
  <a href={to} rel="noopener noreferrer" target={blank && '_blank'} {...rest}>
    {children}
  </a>
);

ExternalLink.defaultProps = {
  blank: true,
};

export default ExternalLink;
