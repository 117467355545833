import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'react-intl';
import {
  ContentfulHeaderLinks,
  ContentfulHeaderLinksConnection,
} from '../graphql-types';

const useHeaderLinks = (): ContentfulHeaderLinks | undefined => {
  const intl = useIntl();
  const { allContentfulHeaderLinks } = useStaticQuery<{
    allContentfulHeaderLinks: ContentfulHeaderLinksConnection;
  }>(graphql`
    {
      allContentfulHeaderLinks {
        nodes {
          node_locale
          links {
            id
            text
            url
            isDisplayed
          }
        }
      }
    }
  `);

  return allContentfulHeaderLinks.nodes.find(
    (node) => node.node_locale === intl.locale
  );
};

export default useHeaderLinks;
