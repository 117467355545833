import { graphql, useStaticQuery } from 'gatsby';
import { Site } from '../graphql-types';

const useSiteMetadata = () => {
  const data: { site: Site } = useStaticQuery(graphql`
    query siteMetadata {
      site {
        siteMetadata {
          siteUrl
          de {
            title
            description
            author
          }
          en {
            title
            description
            author
          }
          en_GB {
            title
            description
            author
          }
        }
      }
    }
  `);

  return data.site.siteMetadata;
};

export default useSiteMetadata;
