const Z_INDEX = {
  base: 1,
  dropdown: 2,
  header: 3,
  notification: 3,
  overlay: 4,
  sidebar: 5,
};

const getZIndex = (name: string) => {
  return (Z_INDEX as { [key: string]: number })[name] || Z_INDEX.base;
};

export default getZIndex;
