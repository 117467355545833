import Typography from 'typography';
import theme from '../styles/theme';

export const SYSTEM_FONTS = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
];

export const MONO_SYSTEM_FONTS = [
  'SFMono',
  'Menlo',
  'Monaco',
  'Consolas',
  'Liberation Mono',
  'Courier New',
  'monospace',
];

const typography = new Typography({
  headerFontFamily: ['Work Sans'].concat(SYSTEM_FONTS),
  headerColor: theme.colors.text,
  headerWeight: 400,
  bodyFontFamily: ['Work Sans'].concat(SYSTEM_FONTS),
  bodyColor: theme.colors.text,
  baseFontSize: '16px',
  baseLineHeight: theme.lineHeights.dense,
  boldWeight: 500,
  overrideStyles: () => ({
    a: {
      color: theme.colors.primary,
      textDecoration: 'none',
    },
    'a:hover': {
      textDecoration: 'underline',
    },
  }),
  googleFonts: [
    { name: 'Work Sans', styles: ['400', '500'] },
    {
      name: 'Source Code Pro',
      // https://github.com/KyleAMathews/typography.js/issues/211#issuecomment-494001270
      styles: ['400', '500', '600', '700&display=swap'],
    },
  ],
});

// Export helper functions
export const { scale, rhythm, options } = typography;
export default typography;
