import { useContext, useEffect } from 'react';
import browserLang from 'browser-lang';
import { IntlContext } from '../components/IntlContext';
import locales from '../locales/config';

export default function useBrowserLocale() {
  const { locale, setLocale } = useContext(IntlContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const defaultLocale = Object.values(locales).find(
        (locale) => locale.default
      );
      const browserLocale = browserLang({
        languages: Object.values(locales).map((locale) => locale.path),
        fallback: defaultLocale?.path || 'en',
      });
      if (locale !== browserLocale) {
        setLocale(browserLocale);
      }
    }
  }, [locale, setLocale]);
}
