import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import hexToRgba from 'hex-to-rgba';
import Link from '../Link';
import { MONO_SYSTEM_FONTS } from '../../styles/typography';

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'type'> {
  as?: string;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  design?: 'filled' | 'transparent' | 'backButton' | 'bordered';
}

const loader = keyframes({
  from: {
    transform: 'translate(-50%, -50%) rotate(0deg)',
  },
  to: {
    transform: 'translate(-50%, -50%) rotate(360deg)',
  },
});

const backgroundColor = (design: string) => {
  switch (design) {
    case 'backButton':
      return '#fff';
    case 'transparent':
    case 'bordered':
      return 'transparent';
    case 'filled':
    default:
      return '#489F9D';
  }
};

const border = (design: string) => {
  switch (design) {
    case 'transparent':
      return '1px solid #fff';
    case 'backButton':
      return '1px solid #000';
    case 'bordered':
      return '1.5px solid #489F9D';
    case 'filled':
    default:
      return '1px solid transparent';
  }
};

const color = (design: string, isLoading: boolean) => {
  switch (design) {
    case 'backButton':
      return isLoading ? 'transparent' : '#000';
    case 'bordered':
      return isLoading ? 'transparent' : '#489F9D';
    case 'filled':
    case 'transparent':
    default:
      return isLoading ? 'transparent' : '#fff';
  }
};

const padding = (design: string) => {
  switch (design) {
    case 'backButton':
      return null;
    case 'bordered':
      return '0.5rem 1.5rem';
    case 'filled':
    case 'transparent':
    default:
      return '1rem 2.5rem';
  }
};

const Button = styled('button')<ButtonProps>(
  ({ isLoading, design = 'filled' }) => ({
    position: 'relative',
    padding: padding(design),
    marginTop: design === 'backButton' && '1.5rem',
    display: 'inline-block',
    maxWidth: '100%',
    border: border(design),
    borderRadius: 6,
    color: color(design, isLoading),
    backgroundColor: backgroundColor(design),
    fontFamily: ['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', '),
    fontWeight: 600,
    fontSize: '0.875rem',
    letterSpacing: 1.5,
    lineHeight: 1.285,
    textAlign: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    outline: 0,
    userSelect: 'none',
    cursor: isLoading ? 'wait' : 'pointer',
    transition:
      'opacity 0.15s cubic-bezier(0.4, 0.1, 1, 1), box-shadow 0.15s cubic-bezier(0.4, 0.1, 1, 1)',

    '&:focus': {
      boxShadow: `0 0 0 4px ${hexToRgba('#489F9D', 0.25)}`,
    },

    '&:disabled': {
      opacity: 0.5,
      cursor: isLoading ? 'wait' : 'not-allowed',
      boxShadow: 'none',
    },

    '@media (hover: hover)': {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    // loader
    '&:after': {
      content: "''",
      position: 'absolute',
      display: isLoading ? 'block' : 'none',
      top: '50%',
      left: '50%',
      width: '1.25rem',
      height: '1.25rem',
      borderRadius: '50%',
      borderTop: `2px solid ${hexToRgba('#fff', 0.2)}`,
      borderRight: `2px solid ${hexToRgba('#fff', 0.2)}`,
      borderBottom: `2px solid ${hexToRgba('#fff', 0.2)}`,
      borderLeft: '2px solid #fff',
      transform: 'translate(-50%, -50%) rotate(0deg)',
      animation: `${loader} 1.1s infinite linear`,
    },
  })
);

export const ButtonLink = Button.withComponent(Link);

export default Button;
