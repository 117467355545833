import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import SchemaOrg from './SchemaOrg';

interface Props {
  title?: string;
  description?: string | null;
  lang?: string;
  meta?: HTMLMetaElement[];
  keywords?: string[];
  noindex?: boolean;
}
type Locales = 'en' | 'de';

const SEO: React.FC<Props> = ({
  description,
  lang = `de`,
  meta = [],
  keywords = [],
  title,
  noindex,
}) => {
  const data = useSiteMetadata();
  const siteData = data[lang as Locales];
  const metaDescription = description || siteData?.description;
  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: `${data.siteUrl}/og_${lang}.jpg`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteData?.author || '@cara_by_hidoc',
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: 'google-site-verification',
      content: 'Fb8QEk_LZKQ5t6aiIndmkOUEeCYsgM-AzVLXtE8TsbY',
    },
    {
      name: 'google-site-verification',
      content: 'uh9EoW8rofYHSZHRZPpNhxYvQJ0FMOJDUs18iim0tj8',
    },
  ]
    .concat(
      keywords.length > 0
        ? {
            name: `keywords`,
            content: keywords.join(`, `),
          }
        : []
    )
    .concat(meta);

  if (noindex) {
    metaTags.push({ name: 'robots', content: 'noindex' });
  }

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{ lang }}
        title={title}
        titleTemplate={`%s - ${siteData?.title}`}
        meta={metaTags}
      />
      {/* Schema.org tags */}
      <SchemaOrg url={data.siteUrl} title={data.de.title} />
    </>
  );
};

export default SEO;
