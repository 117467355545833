import theme from './theme';

interface Presets {
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

const mq = Object.keys(theme.breakpoints).reduce<Presets>(
  (acc, breakpoint) => ({
    ...acc,
    [breakpoint]: `@media (min-width: ${
      theme.breakpoints[breakpoint as keyof Presets]
    }px)`,
  }),
  {} as Presets
);

export default mq;
