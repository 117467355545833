module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true,
    languageNotificationMessage: 'View this site in English',
  },
  de: {
    path: 'de',
    locale: 'Deutsch',
    default: false,
    languageNotificationMessage: 'Diese Seite auf Deutsch anzeigen',
  },
};
