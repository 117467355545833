import styled from '@emotion/styled';

interface Props {
  color?: string;
  disabled?: boolean;
}

const IconButton = styled('button')<Props>`
  color: ${({ color }) => (color ? color : 'inherit')};
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
`;

export default IconButton;
