import styled from '@emotion/styled';

interface ContainerProps {
  fluid?: boolean;
}

const Container = styled('div')(
  {
    paddingLeft: 24,
    paddingRight: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  ({ fluid }: ContainerProps) => ({
    maxWidth: fluid ? '100%' : '1368px',
  })
);

export default Container;
