import React, { CSSProperties } from 'react';
import { PopupButton } from '@typeform/embed-react';
import { MONO_SYSTEM_FONTS } from '../../../styles/typography';
import { useIntl } from 'react-intl';

interface TypeformEmbedProps {
  typeformId: string;
  buttonText: string;
  buttonStyle?: CSSProperties;
  design?: 'filled' | 'transparent' | 'backButton' | 'bordered';
  isLoading?: boolean;
  index?: number;
}

const TypeformEmbedButton: React.FC<TypeformEmbedProps> = ({
  typeformId,
  buttonText,
  buttonStyle,
  design = 'filled',
  isLoading = false,
  index,
}) => {
  const intl = useIntl();

  const backgroundColor = (design: string) => {
    switch (design) {
      case 'backButton':
        return '#fff';
      case 'transparent':
      case 'bordered':
        return 'transparent';
      case 'filled':
      default:
        return '#489F9D';
    }
  };

  const border = (design: string) => {
    switch (design) {
      case 'transparent':
        return '1px solid #fff';
      case 'backButton':
        return '1px solid #000';
      case 'bordered':
        return '2px solid #489F9D';
      case 'filled':
      default:
        return '1px solid transparent';
    }
  };

  const color = (design: string, isLoading: boolean) => {
    switch (design) {
      case 'backButton':
        return isLoading ? 'transparent' : '#000';
      case 'bordered':
        return isLoading ? 'transparent' : '#489F9D';
      case 'filled':
      case 'transparent':
      default:
        return isLoading ? 'transparent' : '#fff';
    }
  };

  const padding = (design: string) => {
    switch (design) {
      case 'backButton':
        return null;
      case 'bordered':
        return '0.5rem 1.5rem';
      case 'filled':
      case 'transparent':
      default:
        return '1rem 2.5rem';
    }
  };

  return (
    <PopupButton
      key={`${typeformId}-${index}-${intl.locale}`}
      onClose={() => {
        document.body.style.overflow = 'auto !important';
      }}
      id={typeformId}
      css={{
        position: 'relative',
        padding: padding(design),
        marginTop: design === 'backButton' && '1.5rem',
        display: 'inline-block',
        maxWidth: '100%',
        border: border(design),
        borderRadius: 6,
        color: color(design, isLoading),
        backgroundColor: backgroundColor(design),
        fontFamily: ['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', '),
        fontWeight: 600,
        fontSize: '0.875rem',
        letterSpacing: 1.5,
        lineHeight: 1.285,
        textAlign: 'center',
        verticalAlign: 'middle',
        textDecoration: 'none',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        outline: 0,
        userSelect: 'none',
        cursor: 'pointer',
        transition:
          'opacity 0.15s cubic-bezier(0.4, 0.1, 1, 1), box-shadow 0.15s cubic-bezier(0.4, 0.1, 1, 1)',
        ...buttonStyle,
      }}
      className="typeform-embed"
      size={100}
      hideHeaders
      hideFooter
    >
      {buttonText}
    </PopupButton>
  );
};

export default TypeformEmbedButton;
