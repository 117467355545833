if (typeof window !== 'undefined') {
  class CaraBanner extends HTMLElement {
    constructor() {
      super();

      const title = this.getAttribute('title');
      const cta = this.getAttribute('cta');
      const link = this.getAttribute('link');
      const image = this.getAttribute('image');

      const shadow = this.attachShadow({ mode: 'open' });

      const style = document.createElement('style');
      style.textContent = `
      .b__link {
        position: relative;
        display: flex;
        flex-direction: column;
        color: inherit;
        margin-bottom: 1rem;
        text-decoration: none;
      }

      .b__img {
        padding: 20px;
        background-image: linear-gradient(90deg,rgba(0,0,0,0.3),rgba(0,0,0,0.1)), url(${image});
        background-size: cover;
        background-repeat: no-repeat;

        @media (min-width: 768px) {
          padding: 10px 225px 23px 18px;
        }
      }

      .b__title {
        display: block;
        margin-bottom: 1rem;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.45;
        letter-spacing: -0.67px;
        color: #fff;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
      }

      .b__btn {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: #00b3a7;
        max-width: 142px;
      }

      .b__btntext {
        font-size: 17px;
        font-weight: 500;
        letter-spacing: -0.26px;
        color: #fff;
      }
    `;

      const linkEl = document.createElement('a');
      linkEl.setAttribute('href', link);
      linkEl.setAttribute('target', '_blank');
      linkEl.setAttribute('rel', 'noopener noreferrer');
      linkEl.setAttribute('class', 'b__link');

      const divEl = document.createElement('div');
      divEl.setAttribute('class', 'b__img');

      const spanEl = document.createElement('span');
      spanEl.setAttribute('class', 'b__title');
      spanEl.textContent = title;

      const divBtnEl = document.createElement('div');
      divBtnEl.setAttribute('class', 'b__btn');

      const spanBtnEl = document.createElement('span');
      spanBtnEl.setAttribute('class', 'b__btntext');
      spanBtnEl.textContent = cta;

      divBtnEl.appendChild(spanBtnEl);
      divEl.appendChild(spanEl);
      divEl.appendChild(divBtnEl);
      linkEl.appendChild(divEl);

      shadow.appendChild(style);
      shadow.appendChild(linkEl);
    }
  }

  customElements.define('cara-banner', CaraBanner);
}
