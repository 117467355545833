import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { injectIntl, WrappedComponentProps } from 'react-intl';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ExternalLink from './ExternalLink';
import { isExternalLink } from '../utils';

type Props = GatsbyLinkProps<{}> & WrappedComponentProps;

const Link: React.FC<Props> = ({ to, intl: { locale }, ...rest }) => {
  switch (true) {
    case isExternalLink(to):
      return <ExternalLink to={to} {...rest} />;
    case to.startsWith('#'):
      return <AnchorLink href={to} {...rest} />;
    case to.startsWith('mailto:'):
    case to.startsWith('tel:'):
      return <ExternalLink to={to} {...rest} blank={false} />;
    default:
      const localizePath = `/${locale}${to}`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return <GatsbyLink to={localizePath} {...rest} />;
  }
};

export default injectIntl(Link);
